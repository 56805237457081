import React, { useState, useEffect } from 'react'
import {
  loadCaptchaEnginge,
  LoadCanvasTemplate,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import logo from '../../assets/img/ms360-logo-1.svg'
import axios from "axios";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";


export default function Modal({isOpen, closeModal}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [captchaError, setCaptchaError] = useState("");
  const [captchaValue, setCaptchaValue] = useState("");
  const [isMounted, setIsMounted] = useState(true); 

  
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      setIsMounted(true);
      loadCaptchaEnginge(6,'#0f172a', 'white');
    } else {
      document.body.style.overflow = 'auto';
      setIsMounted(false);
    }

    const interval = setInterval(() => {
      if (isMounted) {
        loadCaptchaEnginge(6,'#0f172a', 'white');
      }
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  }, [isOpen, isMounted]);

  
  const [formData, setFormData] = useState({
    user_name: "",
    user_email: "",
    user_message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    let userCaptchaValue = e.target.captcha.value;
    // Validate the captcha
    if (!validateCaptcha(userCaptchaValue)) {
      setCaptchaError("Captcha Does Not Match");
      return;
    }
    // Reset the captcha value
    setCaptchaValue("");
    setCaptchaError("");
    setIsSubmitting(true);
    setShowSpinner(true);
  
    try {
      const response = await axios.post(
        'https://maturityspectrum360.com//contact.php',
        formData
      );
      if (response.status === 200) {
        setFormData({
          user_name: '',
          user_email: '',
          user_message: '',
        });
        loadCaptchaEnginge(6,'#0f172a', 'white');
        setMessageSent(true);
      } else {
        console.error('');
      }
    } catch (error) {
      console.error('error');
      if (error.response) {
        console.error('Server Error');
      }  
    } finally {
      setIsSubmitting(false);
      setShowSpinner(false);
    }
  };

  return (
    <>
    {isOpen && (
      <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
          <div
            className="transition-opacity bg-gray-900 bg-opacity-90 fixed inset-0"
            aria-hidden="true"
            onClick={closeModal}
          ></div>

          <span className="hidden sm:inline-block sm:align-middle sm:" aria-hidden="true">​</span>

          <div className="inline-block p-5 overflow-hidden text-left align-bottom border border-blue-600 transition-all transform bg-[#0f172a] rounded-lg shadow-2xl lg:p-16 sm:my-8 sm:align-middle sm:max-w-xl sm:w-full">
            <div>
              <div className=' lg:flex md:flex md:justify-center lg:justify-center'>
            <a
            href="/"
            
            class="inline-flex  items-center"
          >
            <img src={logo} width={42}></img>
            <span class="ml-2  text-sm lg:text-xl font-bold  text-gray-100 uppercase">
              Maturity Spectrum 360
            </span>
          </a>
          </div>


              <div className="mt-3 text-left sm:mt-5">
                <div className=' text-center'>
              <h2 className="mb-8 text-xl lg:text-2xl md:text-2xl font-semibold tracking-widest   inline-block text-white bg-clip-text
                uppercase">Contact Us</h2></div>
                <h1 className="mb-8 text-lg font-light leading-none tracking-tighter text-neutral-400">Drop us a note and we will get back to you within 24 hours.</h1>
                <form className="mt-4" method="post" onSubmit={handleSubmit}>
                    <div className=" md:grid lg:grid lg:grid-cols-2 gap-3">
                      <div className="mb-4">
                        <label
                          htmlFor="name"
                          className="block text-sm font-medium text-gray-400"
                        >
                          Your Name
                        </label>
                        <input
                          type="text"
                          name="user_name"
                          value={formData.user_name}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full bg-[#0f172a] text-white border border-gray-700 rounded-md"
                          placeholder="Your Name"
                          required
                        />
                      </div>
                      <div className="mb-4">
                        <label
                          htmlFor="email"
                          className="block text-sm font-medium text-gray-400"
                        >
                          Your Email
                        </label>
                        <input
                          type="email"
                          name="user_email"
                          value={formData.user_email}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full bg-[#0f172a] border text-white border-gray-700 rounded-md"
                          placeholder="Your Email"
                          required
                        />
                      </div>
                      <div className="col-span-2 mb-4">
                        <label
                          htmlFor="message"
                          className="block text-sm font-medium text-gray-400"
                        >
                          Message
                        </label>
                        <textarea
                          name="user_message"
                          value={formData.user_message}
                          onChange={handleChange}
                          className="mt-1 p-2 w-full bg-[#0f172a] border text-white border-gray-700 rounded-md"
                          placeholder="Leave a message here"
                          style={{ height: "120px" }}
                          required
                        ></textarea>
                      </div>
                      <div className=" py-2">
                        <div className=" flex items-center">
                          <LoadCanvasTemplateNoReload />
                          <IconButton
                            aria-label="Reload Captcha"
                            size="small"
                            color="primary"
                            onClick={() => loadCaptchaEnginge(6,"#0f172a","white")}
                          >
                            <RefreshIcon />
                          </IconButton>
                        </div>
                        <input
                          type="text"
                          name="captcha"
                          value={captchaValue}
                          onChange={(e) => setCaptchaValue(e.target.value)}
                          className="mt-1 p-2 w-full text-white bg-[#0f172a] border border-gray-700 rounded-md"
                          placeholder="Enter Captcha"
                          required
                        />
                        {captchaError && (
                          <p className="text-red-500 text-sm mt-1">
                            {captchaError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mt-4 sm:flex ">
              <div className="mt-3 rounded-lg sm:mt-0 ">
              <button
                  className="items-center block px-10 py-4 text-base font-medium text-center text-white transition duration-500 ease-in-out transform bg-blue-600 rounded-xl hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {showSpinner ? (
                    <div className="h-5 w-5 animate-spin rounded-full text-center border-t-4 border-black mx-auto" />
                  ) : (
                    'Send Message'
                  )}
                </button>
              </div>
            </div>
            {messageSent && (
                          <p className="mt-4 text-lg font-light leading-none tracking-tighter text-neutral-400">
                            Thank you! We will get back to you soon.
                          </p>
                        )}
                  </form>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    )}
  </>
  )
}
